import React, { FunctionComponent } from "react"
import { Page } from "../../components/page/page"
import { PageProps } from "../../types/page"
import { SSR } from "../../components/ssr/ssr"

const DjangoTemplate: FunctionComponent<PageProps<never, never>> = (props) => (
  <Page {...props} metadata={null} includeMetadata={false}>
    <SSR id="django" />
  </Page>
)

export default DjangoTemplate
