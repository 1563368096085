import React, { FunctionComponent } from "react"

export interface SSRProps {
  html?: string
  id?: string
}

export const SSR: FunctionComponent<SSRProps> = ({ id, html = "" }) => (
  <div id={id} dangerouslySetInnerHTML={{ __html: html }} />
)
